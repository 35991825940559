import { useState, useEffect, useContext } from "react";
import {
  getRemoteConfigString,
  REMOTE_CONFIG_STRING_KEYS,
} from "../services/firebase-remote-config";
import { RemoteConfigContext } from "../providers/RemoteConfigProvider";

const useRemoteConfig = (key: REMOTE_CONFIG_STRING_KEYS) => {
  const config = useContext(RemoteConfigContext);
  const [value, setValue] = useState<string | null>(null);
  const [useDefault, setUseDefault] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (config.initialized) {
      setValue(getRemoteConfigString(key) ?? null);
    } else if (config.failedToInitialize) {
      setUseDefault(true);
    }

    setIsLoading(false);
  }, [key, config]);

  return [value, useDefault, isLoading];
};

export default useRemoteConfig;
