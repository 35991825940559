import { GeolocationI } from "../services/ipgeolocation";
import {
  CA_PRICE_IDS,
  DYNAMIC_PRICE_IDS,
  EU_PRICE_IDS,
  US_PRICE_IDS,
  GB_PRICE_IDS,
  NZ_PRICE_IDS,
  AU_PRICE_IDS,
} from "./local-price-ids";
import { PriceDataT } from "./types";

const CURRENCY = {
  USD: { code: "USD", name: "US Dollar", symbol: "$" },
  EUR: { code: "EUR", name: "Euro", symbol: "€" },
  CAD: { code: "CAD", name: "Canadian Dollar", symbol: "$" },
  GBP: { code: "GBP", name: "British Pound", symbol: "£" },
  AUD: { code: "AUD", name: "Australian Dollar", symbol: "$" },
  NZD: { code: "NZD", name: "New Zealand Dollar", symbol: "$" },
};

export const getPriceDataFromLocation = (
  location: GeolocationI,
  priceTest: boolean,
): PriceDataT => {
  if (location.is_eu) {
    return EU_PRICE_WEB;
  }

  switch (location.country_code2) {
    case "GB":
      return priceTest ? GB_PRICE_WEB_TEST : GB_PRICE_WEB;
    case "CA":
      return priceTest ? CA_PRICE_WEB_TEST : CA_PRICE_WEB;
    case "AU":
      return priceTest ? AU_PRICE_WEB_TEST : AU_PRICE_WEB;
    case "NZ":
      return priceTest ? NZ_PRICE_WEB_TEST : NZ_PRICE_WEB;
    default:
      return priceTest ? US_PRICE_WEB_TEST : US_PRICE_WEB;
  }
};

export const getPriceDataFromLocationWithSpecialDiscount = (location: GeolocationI): PriceDataT => {
  if (location.is_eu) {
    return EU_PRICE_WEB_SPECIAL_DISCOUNT;
  }

  switch (location.country_code2) {
    case "GB":
      return GB_PRICE_WEB_SPECIAL_DISCOUNT;
    case "CA":
      return CA_PRICE_WEB_SPECIAL_DISCOUNT;
    case "AU":
      return AU_PRICE_WEB_SPECIAL_DISCOUNT;
    case "NZ":
      return NZ_PRICE_WEB_SPECIAL_DISCOUNT;
    default:
      return US_PRICE_WEB_SPECIAL_DISCOUNT;
  }
};

export const getAppPriceDataFromLocation = (location: GeolocationI): PriceDataT => {
  if (location.is_eu) {
    return EU_PRICE_APP;
  }

  switch (location.country_code2) {
    case "GB":
      return GB_PRICE_APP;
    case "CA":
      return CA_PRICE_APP;
    case "AU":
      return AU_PRICE_APP;
    case "NZ":
      return NZ_PRICE_APP;
    default:
      return US_PRICE_APP;
  }
};

// US

export const US_PRICE_WEB: PriceDataT = {
  annual_price: "124.99",
  discounted_annual_price: "99.99",
  price: "10.42",
  discounted_price: "8.34",
  price_id: US_PRICE_IDS.trial[124.99],
  price_id_no_trial: US_PRICE_IDS.no_trial[124.99],
  country_code: "US",
  currency: CURRENCY.USD,
};

export const US_PRICE_WEB_TEST: PriceDataT = {
  annual_price: "161.85",
  discounted_annual_price: "129.48",
  price: "13.49",
  discounted_price: "10.79",
  price_id: DYNAMIC_PRICE_IDS.trial[161.85],
  country_code: "US",
  currency: CURRENCY.USD,
};

export const US_PRICE_WEB_SPECIAL_DISCOUNT: PriceDataT = {
  annual_price: "124.99",
  discounted_annual_price: "87.49",
  price: "10.42",
  discounted_price: "7.30",
  price_id: DYNAMIC_PRICE_IDS.embeddables[124.99],
  country_code: "US",
  currency: CURRENCY.USD,
};

export const US_PRICE_APP: PriceDataT = {
  annual_price: "99.99",
  discounted_annual_price: "79.99",
  price: "10.42",
  discounted_price: "8.34",
  price_id: US_PRICE_IDS.trial[99.99],
  price_id_no_trial: US_PRICE_IDS.no_trial[99.99],
  country_code: "US",
  currency: CURRENCY.USD,
};

// EU

export const EU_PRICE_WEB: PriceDataT = {
  annual_price: "112.49",
  discounted_annual_price: "89.99",
  price: "9.38",
  discounted_price: "7.50",
  price_id: EU_PRICE_IDS.trial[112.49],
  price_id_no_trial: EU_PRICE_IDS.no_trial[112.49],
  country_code: "EU",
  currency: CURRENCY.EUR,
};

export const EU_PRICE_WEB_TEST: PriceDataT = {
  annual_price: "142.35",
  discounted_annual_price: "113.88",
  price: "11.86",
  discounted_price: "9.49",
  price_id: DYNAMIC_PRICE_IDS.trial[161.85],
  country_code: "EU",
  currency: CURRENCY.EUR,
};

export const EU_PRICE_WEB_SPECIAL_DISCOUNT: PriceDataT = {
  annual_price: "112.49",
  discounted_annual_price: "78.74",
  price: "9.38",
  discounted_price: "6.56",
  price_id: DYNAMIC_PRICE_IDS.embeddables[124.99],
  country_code: "EU",
  currency: CURRENCY.EUR,
};

export const EU_PRICE_APP: PriceDataT = {
  annual_price: "89.99",
  discounted_annual_price: "71.99",
  price: "9.38",
  discounted_price: "7.50",
  price_id: EU_PRICE_IDS.trial[89.99],
  price_id_no_trial: EU_PRICE_IDS.no_trial[89.99],
  country_code: "EU",
  currency: CURRENCY.EUR,
};

// CA

export const CA_PRICE_WEB: PriceDataT = {
  annual_price: "156.24",
  discounted_annual_price: "124.99",
  price: "13.02",
  discounted_price: "10.42",
  price_id: CA_PRICE_IDS.trial[156.24],
  price_id_no_trial: CA_PRICE_IDS.no_trial[156.24],
  country_code: "CA",
  currency: CURRENCY.CAD,
};

export const CA_PRICE_WEB_TEST: PriceDataT = {
  annual_price: "199.35",
  discounted_annual_price: "159.48",
  price: "16.61",
  discounted_price: "13.29",
  price_id: DYNAMIC_PRICE_IDS.trial[161.85],
  country_code: "CA",
  currency: CURRENCY.CAD,
};

export const CA_PRICE_WEB_SPECIAL_DISCOUNT: PriceDataT = {
  annual_price: "156.24",
  discounted_annual_price: "109.37",
  price: "13.02",
  discounted_price: "9.11",
  price_id: DYNAMIC_PRICE_IDS.embeddables[124.99],
  country_code: "CA",
  currency: CURRENCY.CAD,
};

export const CA_PRICE_APP: PriceDataT = {
  annual_price: "124.99",
  discounted_annual_price: "99.99",
  price: "13.02",
  discounted_price: "10.42",
  price_id: CA_PRICE_IDS.trial[124.99],
  price_id_no_trial: CA_PRICE_IDS.no_trial[124.99],
  country_code: "CA",
  currency: CURRENCY.CAD,
};

// GB

export const GB_PRICE_WEB: PriceDataT = {
  annual_price: "87.49",
  discounted_annual_price: "69.99",
  price: "7.30",
  discounted_price: "5.84",
  price_id: GB_PRICE_IDS.trial[87.49],
  price_id_no_trial: GB_PRICE_IDS.no_trial[87.49],
  country_code: "GB",
  currency: CURRENCY.GBP,
};

export const GB_PRICE_WEB_SPECIAL_DISCOUNT: PriceDataT = {
  annual_price: "87.49",
  discounted_annual_price: "61.24",
  price: "7.30",
  discounted_price: "5.10",
  price_id: DYNAMIC_PRICE_IDS.embeddables[124.99],
  country_code: "GB",
  currency: CURRENCY.GBP,
};

export const GB_PRICE_WEB_TEST: PriceDataT = {
  annual_price: "109.35",
  discounted_annual_price: "87.48",
  price: "9.11",
  discounted_price: "7.29",
  price_id: DYNAMIC_PRICE_IDS.trial[161.85],
  country_code: "GB",
  currency: CURRENCY.GBP,
};

export const GB_PRICE_APP: PriceDataT = {
  annual_price: "69.99",
  discounted_annual_price: "55.99",
  price: "7.30",
  discounted_price: "5.84",
  price_id: GB_PRICE_IDS.trial[69.99],
  price_id_no_trial: GB_PRICE_IDS.no_trial[69.99],
  country_code: "GB",
  currency: CURRENCY.GBP,
};

// AU

export const AU_PRICE_WEB: PriceDataT = {
  annual_price: "187.49",
  discounted_annual_price: "149.99",
  price: "15.63",
  discounted_price: "12.50",
  price_id: AU_PRICE_IDS.trial[187.49],
  price_id_no_trial: AU_PRICE_IDS.no_trial[187.49],
  country_code: "AU",
  currency: CURRENCY.AUD,
};

export const AU_PRICE_WEB_SPECIAL_DISCOUNT: PriceDataT = {
  annual_price: "187.49",
  discounted_annual_price: "131.24",
  price: "15.63",
  discounted_price: "10.94",
  price_id: DYNAMIC_PRICE_IDS.embeddables[124.99],
  country_code: "AU",
  currency: CURRENCY.AUD,
};

export const AU_PRICE_WEB_TEST: PriceDataT = {
  annual_price: "239.85",
  discounted_annual_price: "191.88",
  price: "19.99",
  discounted_price: "15.99",
  price_id: DYNAMIC_PRICE_IDS.trial[161.85],
  country_code: "AU",
  currency: CURRENCY.AUD,
};

export const AU_PRICE_APP: PriceDataT = {
  annual_price: "149.99",
  discounted_annual_price: "119.99",
  price: "15.63",
  discounted_price: "12.50",
  price_id: AU_PRICE_IDS.trial[149.99],
  price_id_no_trial: AU_PRICE_IDS.no_trial[149.99],
  country_code: "AU",
  currency: CURRENCY.AUD,
};

// NZ

export const NZ_PRICE_WEB: PriceDataT = {
  annual_price: "187.49",
  discounted_annual_price: "149.99",
  price: "15.63",
  discounted_price: "12.50",
  price_id: NZ_PRICE_IDS.trial[187.49],
  price_id_no_trial: NZ_PRICE_IDS.no_trial[187.49],
  country_code: "NZ",
  currency: CURRENCY.NZD,
};

export const NZ_PRICE_WEB_SPECIAL_DISCOUNT: PriceDataT = {
  annual_price: "187.49",
  discounted_annual_price: "131.24",
  price: "15.63",
  discounted_price: "10.94",
  price_id: DYNAMIC_PRICE_IDS.embeddables[124.99],
  country_code: "NZ",
  currency: CURRENCY.NZD,
};

export const NZ_PRICE_WEB_TEST: PriceDataT = {
  annual_price: "239.85",
  discounted_annual_price: "191.88",
  price: "19.99",
  discounted_price: "15.99",
  price_id: DYNAMIC_PRICE_IDS.trial[161.85],
  country_code: "NZ",
  currency: CURRENCY.NZD,
};

export const NZ_PRICE_APP: PriceDataT = {
  annual_price: "149.99",
  discounted_annual_price: "119.99",
  price: "15.63",
  discounted_price: "12.50",
  price_id: NZ_PRICE_IDS.trial[149.99],
  price_id_no_trial: NZ_PRICE_IDS.no_trial[149.99],
  country_code: "NZ",
  currency: CURRENCY.NZD,
};
