// These price IDs include international currencies, and can be accessed by passing
// a currency code to the cloud function(s). Key values are in USD.
export const DYNAMIC_PRICE_IDS = {
  trial: {
    161.85: "price_1PbmbWLvyjRKGUTXPsDXHKJp",
  },
  embeddables: {
    124.99: "price_1Pqn4BLvyjRKGUTXFzFNdPM4",
  },
};

// dev price ids
// export const DYNAMIC_PRICE_IDS = {
//   trial: {
//     161.85: "price_1NUD3KLvyjRKGUTXWMFsCyDg",
//   },
// };

export const US_PRICE_IDS = {
  trial: {
    99.99: "price_1JZipULvyjRKGUTXaG2RtwSq",
    124.99: "price_1NPs3gLvyjRKGUTXkNJG5iX5",
    149.99: "price_1NToWjLvyjRKGUTXFI4a2u0x",
  },
  no_trial: {
    99.99: "price_1KHDXcLvyjRKGUTXl8dDPhK4",
    124.99: "price_1NQXi2LvyjRKGUTXLzKy1evn",
    149.99: "price_1NToVrLvyjRKGUTXY5n9rrVN",
  },
};

// dev price ids
// export const US_PRICE_IDS = {
//   no_trial: {
//     99.99: "price_1LIZdTLvyjRKGUTXiCkeJeqH",
//     124.99: "price_1NUD3KLvyjRKGUTXWMFsCyDg",
//     149.99: "price_1NUD3vLvyjRKGUTXzpaKdryp",
//   },
//   trial: {
//     99.99: "price_1LIZdTLvyjRKGUTXiCkeJeqH",
//     124.99: "price_1NUD3KLvyjRKGUTXWMFsCyDg",
//     149.99: "price_1NUD3vLvyjRKGUTXzpaKdryp",
//   },
// };

export const EU_PRICE_IDS = {
  trial: {
    89.99: "price_1NnjaLLvyjRKGUTXMSZnHiNz",
    112.49: "price_1Nf75SLvyjRKGUTXaE7rG09j",
  },
  no_trial: {
    89.99: "price_1NnjeLLvyjRKGUTXjhCxWuJh",
    112.49: "price_1NkY1QLvyjRKGUTXVzKfGXjJ",
  },
};

export const GB_PRICE_IDS = {
  trial: {
    69.99: "price_1NnjbaLvyjRKGUTXtfYE9kZ1",
    87.49: "price_1Nf789LvyjRKGUTXbBHpErVT",
  },
  no_trial: {
    69.99: "price_1NnjeaLvyjRKGUTXdl0w53vq",
    87.49: "price_1NkXzvLvyjRKGUTX4zpLWzXG",
  },
};

export const CA_PRICE_IDS = {
  trial: {
    124.99: "price_1Nnjc1LvyjRKGUTXO9IxzDZN",
    156.24: "price_1Nf78RLvyjRKGUTX1AlSnyog",
  },
  no_trial: {
    124.99: "price_1NnjfHLvyjRKGUTXsfN0BXWq",
    156.24: "price_1NkXzKLvyjRKGUTXPUQ92cnB",
  },
};

export const AU_PRICE_IDS = {
  trial: {
    149.99: "price_1NnjcTLvyjRKGUTX9DVEm5Im",
    187.49: "price_1Nf75nLvyjRKGUTXBQwDdsxa",
  },
  no_trial: {
    149.99: "price_1NnjfiLvyjRKGUTXLJrv5g8T",
    187.49: "price_1NkY13LvyjRKGUTX8BV93Zxo",
  },
};

export const NZ_PRICE_IDS = {
  trial: {
    149.99: "price_1NnjcxLvyjRKGUTXzda4P8ND",
    187.49: "price_1Nf757LvyjRKGUTXlQXPkMy3",
  },
  no_trial: {
    149.99: "price_1Nnjg3LvyjRKGUTXbc3rp1pG",
    187.49: "price_1NkY1wLvyjRKGUTXsq2BU9hd",
  },
};
